import React from "react";
import { useAuthContext } from "../../../context/AuthContext";
import WestIcon from "@mui/icons-material/West";
import EastIcon from "@mui/icons-material/East";
import play_icon from "../../../images/icons/play-icon.svg";
import lock_icon from "../../../images/icons/lock-icon.svg";
import "./ChaptersAndModules.scss";

const NUM_TITLE_CHARACTERS = 20;

const ChaptersAndModules = ({ chapters, chapterIndex, handleChapterChange, moduleList, currentModule, handleModuleChange }) => {
    const { user, reduxUser } = useAuthContext();

    const changeChapter = (e, chapterIndex) => {
        e.preventDefault();
        handleChapterChange(chapterIndex);
    }

    const changeModule = (e, moduleId) => {
        e.preventDefault();

        const buttons = document.querySelector(".chapters-and-modules").getElementsByClassName("module-button");
        const clickedButton = e.target.className === "text" ? e.target.parentNode : e.target;

        if (!clickedButton.classList.contains("active")) {
            for (let i = 0; i < buttons.length; i++) {
                buttons[i].classList.remove("active");
            }

            clickedButton.classList.add("active");
            handleModuleChange(moduleId);
        }
    }

    return (
        <section className="chapters-and-modules">
            <div className="chapters">
                <h2 className="centered">{chapters[chapterIndex].titel}</h2>
                <div className="chapter-number">
                    <div className="left">
                        <div className="text centered">{chapterIndex}</div>
                    </div>
                    <div className="middle">
                        <div className="text centered">/</div>
                    </div>
                    <div className="right">
                        <div className="text centered">{chapters.length - 1}</div>
                    </div>
                </div>
                {chapterIndex > 1
                    ? <div
                        className="previous"
                        onClick={(e) => changeChapter(e, chapterIndex - 1)}
                    >
                        <WestIcon className="centered" />
                    </div>
                    : <div
                        className="previous disabled"
                    >
                        <WestIcon className="centered" />
                    </div>
                }

                {chapterIndex < chapters.length - 1
                    ? <div
                        className="next"
                        onClick={(e) => changeChapter(e, chapterIndex + 1)}
                    >
                        <EastIcon className="centered" />
                    </div>
                    : <div
                        className="next disabled"
                    >
                        <EastIcon className="centered" />
                    </div>
                }
            </div>
            <div className="modules">
                {moduleList.map((m) => (
                    <div
                        key={m.id}
                        className={"module-button" + (currentModule === m.id ? " active" : "") + (((!user.premium || !reduxUser.premium) && m.premium) ? " locked" : "")}
                        onClick={(e) => {
                            if ((!user.premium || !reduxUser.premium) && m.premium) {
                                document.getElementById("premium-item-clicked-button").click();                                
                            } else {
                                changeModule(e, m.id);
                            }
                        }}
                    >
                        {(!user.premium || !reduxUser.premium) && m.premium
                            ? <img alt="Niet toegestaan" src={lock_icon} />
                            : <img alt="Les volgen" src={play_icon} />
                        }
                        <div className="text">{(m.titel.slice(0, NUM_TITLE_CHARACTERS)[NUM_TITLE_CHARACTERS - 1] === " "
                            ? m.titel.slice(0, NUM_TITLE_CHARACTERS - 1)
                            : m.titel.slice(0, NUM_TITLE_CHARACTERS))
                            + (m.titel.length > NUM_TITLE_CHARACTERS ? "..." : "")}</div>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default ChaptersAndModules;