import React from "react";
import { TERMS_ROUTE } from "../../../constants";
import youtube_icon from "../../../images/icons/youtube-icon.svg";
import tiktok_icon from "../../../images/icons/tiktok-icon.svg";
import instagram_icon from "../../../images/icons/instagram-icon.svg";
import snapchat_icon from "../../../images/icons/snapchat-icon.svg";
import facebook_icon from "../../../images/icons/facebook-icon.svg";
import "./Footer.scss";

const Footer = () => {
    return (
        <footer className="footer">
            <div className="wrapper">
                <section className="schedule">
                    <h4>Openingstijden</h4>
                    <p>ma-vr: 10:00-17:00 uur</p>
                </section>
                <section className="social-media">
                    <a href="https://www.snapchat.com/add/theorieja" target="_blank" rel="noreferrer">
                        <img className="snapchat-icon" alt="Snapchat" src={snapchat_icon} />
                    </a>
                    <a href="https://www.tiktok.com/@theorieja" target="_blank" rel="noreferrer">
                        <img className="tiktok-icon" alt="TikTok" src={tiktok_icon} />
                    </a>
                    <a href="https://www.facebook.com/theorieja.nl/" target="_blank" rel="noreferrer">
                        <img className="facebook-icon" alt="Facebook" src={facebook_icon} />
                    </a>
                    <a href="https://www.instagram.com/theorieja/" target="_blank" rel="noreferrer">
                        <img className="instagram-icon" alt="Instagram" src={instagram_icon} />
                    </a>
                    <a href="https://www.youtube.com/@theorieja" target="_blank" rel="noreferrer">
                        <img className="youtube-icon" alt="YouTube" src={youtube_icon} />
                    </a>
                </section>
                <p className="terms"><a href={TERMS_ROUTE}>Algemene voorwaarden</a></p>
            </div>
        </footer>
    );
};

export default Footer;