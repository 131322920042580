// API
// export const BASE = "https://theorieja-api.com";
export const BASE = "https://theorieja-leren-api.com";
// export const BASE = "http://localhost:1337";
export const API = BASE + "/api";
export const CREATE_ORDER_API = API + "/create-order";
export const PURCHASE_REGISTER_API = API + "/auth/purchase-register";
export const PURCHASE_SIGN_IN_API = API + "/auth/purchase-sign-in";
export const REGISTER_API = API + "/auth/register";
export const SIGN_IN_API = API + "/auth/sign-in";
export const FORGOT_PASSWORD_API = API + "/auth/forgot-password";
export const RESET_PASSWORD_API = API + "/auth/password-reset";
export const FETCH_USER_API = API + "/user/fetch";
export const FIRST_TIME_API = API + "/user/first-time";
export const UPDATE_FIRST_TIME_API = API + "/user/update-first-time";
export const SWITCH_VEHICLE_API = API + "/user/switch-vehicle";
export const MESSAGES_API = API + "/messages";
export const PAUSE_PACKAGE_API = API + "/auth/pause-package";
export const RESUME_PACKAGE_API = API + "/auth/resume-package";
export const ORDERS_API = API + "/orders";
export const USE_LICENSE_CODE_API = API + "/use-license-code";
export const CHECK_DISCOUNT_CODE_API = API + "/check-discount-code";
export const CHANGE_PROFILE_API = API + "/user/change-profile";
export const CHANGE_PASSWORD_API = API + "/auth/password-change";
export const HAZARD_QUESTIONS_API = API + "/hazard-questions";
export const UPDATE_HAZARD_ANSWER_API = API + "/update-hazard-answer";
export const FINISH_HAZARD_API = API + "/finish-hazard";
export const ANALYZE_HAZARD_API = API + "/analyze-hazard";
export const RETRY_HAZARD_API = API + "/retry-hazard";
export const RESET_HAZARD_API = API + "/reset-hazard";
export const EXAM_STATUSSES_API = API + "/exam-statusses";
export const CREATE_EXAM_STATUS_API = API + "/create-exam-status";
export const UPDATE_CURRENT_EXAM_STATUS_API = API + "/update-current-exam-status";
export const CONTINUE_EXAM_STATUS_API = API + "/continue-exam-status";
export const FINISH_EXAM_STATUS_API = API + "/finish-exam-status";
export const SAVE_EXAM_STATUS_API = API + "/save-exam-status";
export const SET_SECONDS_LEFT_EXAM_STATUS_API = API + "/set-seconds-left-exam-status";
export const PROGRESS_API = API + "/progress";
export const EXAM_API = API + "/exam";
export const FINISH_EXAM_API = API + "/finish-exam";
export const CHAPTERS_AND_MODULES_API = API + "/chapters-and-modules";
export const MODULE_API = API + "/module";
export const UPDATE_CURRENT_MODULE_API = API + "/update-current-module";

// Routes
export const HOME_ROUTE = "/";
export const PURCHASE_ROUTE = "/bestellen";
export const REGISTER_ROUTE = "/registreren-met-licentiecode";
export const SIGN_IN_ROUTE = "/inloggen";
export const SIGN_OUT_ROUTE = "/uitloggen";
export const FORGOT_PASSWORD_ROUTE = "/wachtwoord-vergeten";
export const RESET_PASSWORD_ROUTE = "/wachtwoord-resetten";
export const PRIVACY_ROUTE = "/privacybeleid";
export const TERMS_ROUTE = "/algemene-voorwaarden";
export const RESTITUTION_ROUTE = "/restitutiebeleid";
export const DASHBOARD_ROUTE = "/dashboard";
export const NAVIGATE_TO_HOME_ROUTE_PARAMETER = "home";
export const PACKAGES_ROUTE_PARAMETER = "pakketten";
export const VIDEOS_ROUTE = "/les-volgen";
export const HAZARDS_ROUTE = "/gevaarherkenning-overzicht";
export const HAZARD_ROUTE = "/gevaarherkenning";
export const ANALYZE_ROUTE_STATE = "analysis";
export const RETRY_ROUTE_STATE = "retry";
export const EXAMS_ROUTE = "/examen-overzicht";
export const EXAM_ROUTE = "/examen";
export const DOWNLOADS_ROUTE = "/bestanden";
export const PRODUCTIVITY_ROUTE = "/statestieken";
export const PROFILE_ROUTE = "/mijn-gegevens";
export const ORDERS_ROUTE = "/mijn-aankopen";
export const PACKAGE_PAUSED_ROUTE = "/pakket-gepauzeerd";
export const PACKAGE_EXPIRED_ROUTE = "/pakket-verlengen";
export const PACKAGE_DELAYED_ROUTE = "/pakket-activeren";

// Anchors
export const TOP_ANCHOR = "#top";
export const SCROLL_DOWN_DESKTOP_ANCHOR = "#scroll-down-desktop";
export const SCROLL_DOWN_MOBILE_ANCHOR = "#scroll-down-mobile";
export const PACKAGES_DESKTOP_ANCHOR = "#packages-desktop";
export const PACKAGES_MOBILE_ANCHOR = "#packages-mobile";
export const CONTACT_ANCHOR = "#contact";

// Authentication texts
export const REGISTER_HEADING = "Starten met je licentiecode";
export const REGISTER_PARAGRAPH = "Met een licentiecode kun je zelfstandig inloggen en starten met oefenen. Voer de code in op exact de zelfde manier.";
export const SIGN_IN_HEADING = "Log in om te starten";
export const FORGOT_PASSWORD_HEADING = "Nieuw wachtwoord instellen";
export const FORGOT_PASSWORD_LINE = "Als je e-mailadres herkend wordt, ontvang je een mail.";
export const RESET_PASSWORD_HEADING = "Nieuw wachtwoord instellen";
export const CHANGE_PROFILE_HEADING = "Profiel wijzigen";
export const CHANGE_PASSWORD_HEADING = "Wachtwoord wijzigen";
export const CODE_HEADING = "Ik heb een licentiecode";
export const HAS_LICENSE_CODE_HEADING = "Heb je een licentiecode?";

// Button texts
export const ORDER_BUTTON = "BESTELLEN";
export const REGISTER_BUTTON = "Registreren";
export const SIGN_IN_BUTTON = "Inloggen";
export const FORGOT_PASSWORD_SUBMIT_1_BUTTON = "Verstuur e-mail";
export const FORGOT_PASSWORD_SUBMIT_2_BUTTON = "Verstuur opnieuw";
export const RESET_PASSWORD_BUTTON = "Wachtwoord wijzigen";
export const CHANGE_PROFILE_BUTTON = "Gegevens opslaan";
export const CHANGE_PASSWORD_BUTTON = "Wachtwoord wijzigen";
export const USE_LICENSE_CODE_BUTTON = "Opwaarderen";

// Link texts
export const HAS_ACCOUNT_LINK = "Ik heb al een account";
export const HAS_NO_ACCOUNT_LINK = "Ik heb nog geen account";
export const FORGOT_PASSWORD_LINK = "Wachtwoord vergeten";
export const SIGN_OUT_LINK = "Uitloggen";
export const BACK_TO_SIGN_IN_LINK = "Terug naar de inlogpagina";
export const HAS_LICENSE_CODE_LINK = "Ik heb een licentiecode";
export const BACK_TO_HOME_IN_LINK = "Terug naar de homepagina";
export const BACK_TO_DASHBOARD_IN_LINK = "Ga naar het dashboard";

// Placeholders
export const LICENSE_CODE_PLACEHOLDER = "plak hier je licentiecode in";
export const EMAIL_PLACEHOLDER = "e-mailadres";
export const EMAIL_CONFIRMATION_PLACEHOLDER = "e-mailadres herhalen";
export const PASSWORD_PLACEHOLDER = "wachtwoord";
export const PASSWORD_CONFIRMATION_PLACEHOLDER = "wachtwoord herhalen";
export const DISCOUNT_CODE_PLACEHOLDER = "kortingscode";
export const OLD_PASSWORD_PLACEHOLDER = "oud wachtwoord";
export const NEW_PASSWORD_PLACEHOLDER = "nieuw wachtwoord";
export const NEW_PASSWORD_CONFIRMATION_PLACEHOLDER = "nieuw wachtwoord herhalen";
export const FIRST_NAME_PLACEHOLDER = "voornaam";
export const LAST_NAME_PLACEHOLDER = "achternaam";
export const TELEPHONE_NUMBER_PLACEHOLDER = "telefoonnummer";
export const STREET_PLACEHOLDER = "straatnaam";
export const HOUSENUMBER_PLACEHOLDER = "huisnummer";
export const POSTAL_CODE_PLACEHOLDER = "postcode";
export const CITY_PLACEHOLDER = "stad";

// Errors
export const FIRST_NAME_CHECK = "Voornaam is te lang";
export const INVALID_EMAIL_CHECK = "Ongeldig e-mailadres";
export const INVALID_PASSWORD_CHECK = "Wachtwoord moet uit minstens 8 tekens, 1 hoofdletter, 1 kleine letter en 1 cijfer bestaan";
export const EMAIL_MISMATCH_CHECK = "E-mailadressen komen niet overeen";
export const PASSWORD_MISMATCH_CHECK = "Wachtwoorden komen niet overeen";
export const SAME_EMAIL_CHECK = "Nieuw e-mailadres is hetzelfde als de oude";
export const SAME_PASSWORD_CHECK = "Nieuw wachtwoord is hetzelfde als de oude";

// API errors
export const EMAIL_TAKEN_API_ERROR = "Email or Username are already taken";
export const INVALID_LICENSE_CODE_API_ERROR = "License code does not exist";
export const INVALID_SIGN_IN_API_ERROR = "Invalid identifier or password";
export const INVALID_DISCOUNT_CODE_API_ERROR = "Discount code does not exist";
export const UNAUTHORIZED_API_ERROR = "Unauthorized";
export const NO_JWT_API_ERROR = "No JWT token provided";
export const INACTIVE_JWT_API_ERROR = "Inactive JWT token";
export const PACKAGE_ALREADY_PAUSED_API_ERROR = "Package currently paused";
export const PACKAGE_PAUSE_NOT_ALLOWED_API_ERROR = "Package has already been paused";
export const CHANGE_PASSWORD_INVALID_API_ERROR = "The provided current password is invalid";
export const CHANGE_PASSWORD_NOT_DIFFERENT_API_ERROR = "Your new password must be different than your current password";
export const UNKNOWN_API_ERROR = "An unknown error occurred";

// Messages
export const REGISTERED_MESSAGE = "Je bent geregistreerd!";
export const SIGNED_IN_MESSAGE = "Welkom terug!";
export const FORGOT_PASSWORD_SUBMITTED_MESSAGE = "Je ontvangt een e-mail met een link om je wachtwoord te resetten";
export const PACKAGE_EXTENDED_MESSAGE = "Je pakket is verlengd!";
export const CHANGED_PROFILE_MESSAGE = "Gegevens opgeslagen";
export const CHANGED_PASSWORD_MESSAGE = "Wachtwoord gewijzigd";

// Success messages
export const PACKAGE_PAUSED_MESSAGE = "Je pakket is gepauzeerd";

// Error messages
export const EMAIL_TAKEN_MESSAGE = "E-mailadres al in gebruik. Log in met je account.";
export const PROFILE_CHANGE_EMAIL_TAKEN_MESSAGE = "E-mailadres al in gebruik";
export const INVALID_LICENSE_CODE_MESSAGE = "Deze licentiecode bestaat niet";
export const INVALID_SIGN_IN_MESSAGE = "E-mailadres of wachtwoord klopt niet";
export const INVALID_DISCOUNT_CODE_MESSAGE = "Deze kortingscode bestaat niet";
export const UNAUTHORIZED_MESSAGE = "Je bent niet ingelogd";
export const OTHER_DEVICE_MESSAGE = "Je bent ingelogd op een ander apparaat";
export const PACKAGE_ALREADY_PAUSED_MESSAGE = "Je pakket is al gepauzeerd";
export const PACKAGE_PAUSE_NOT_ALLOWED_MESSAGE = "Je pakket is al een keer gepauzeerd";
export const CHANGE_PASSWORD_INVALID_MESSAGE = "Oud wachtwoord klopt niet";
export const CHANGE_PASSWORD_NOT_DIFFERENT_MESSAGE = "Nieuw wachtwoord mag niet hetzelfde zijn als oud wachtwoord";
export const ERROR_MESSAGE = "Er is een fout opgetreden";

// Other
export const MAX_ALLOWED_PAUSES = 3;