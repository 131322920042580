import React from "react";
import { SCROLL_DOWN_DESKTOP_ANCHOR } from "../../../constants";
import logo_icon from "../../../images/logos/logo-icon.svg";
import shadow_icon from "../../../images/icons/shadow-icon.png";
import "./About.scss";
import "./OnlinePracticeDesktop.scss";

const OnlinePracticeDesktop = () => {
    return (
        <section className="online-practice-desktop about-section-desktop">
            <div id={SCROLL_DOWN_DESKTOP_ANCHOR} className="scroll-point" />
            <div className="wrapper">
                <div className="heading-text">Online oefenen. <span>Dé manier om gewoon lekker te slagen.</span></div>
                <div className="dedicated-info about-block">
                    <h3>Examengericht oefenen. <span>Zo voorkom je verassingen.</span></h3>
                    <p>Je gaat aan de slag met vragen die je kan verwachten op je theorie-examen.<br />Zo spannend is het niet meer als alles bekend voor komt.</p>
                    <img className="logo-icon" alt="Logo" src={logo_icon} />
                    <img className="shadow-icon" alt="Schaduw" src={shadow_icon} />
                </div>
                <div className="situation-info about-block">
                    <h3>'s Avonds in je bed of tijdens je pauze. In de bus? <span>Het kan overal en altijd.</span></h3>
                    <p>In de bus zitten of een lunchpauze nemen maak je nu lekker productief door te leren voor je theorie.</p>
                </div>
                <div className="explained-info about-block">
                    <h3>Gewoon lekker duidelijk uitgelegd. <span>Onbeperkt herhalen.</span></h3>
                    <p>Een hoofdstuk niet begrepen? Check hem opnieuw. Zak je voor een oefenexamen? We zijn niet op school dus geniet van onbeperkte herkansingen 😉</p>
                </div>
            </div>
        </section>
    );
};

export default OnlinePracticeDesktop;