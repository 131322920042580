import React from "react";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import { getJWT } from "../../../helpers/jwt";
import { BASE, CONTINUE_EXAM_STATUS_API, CREATE_EXAM_STATUS_API, ERROR_MESSAGE, EXAM_ROUTE, INACTIVE_JWT_API_ERROR, NO_JWT_API_ERROR, OTHER_DEVICE_MESSAGE, SIGN_OUT_ROUTE, UNAUTHORIZED_API_ERROR, UNAUTHORIZED_MESSAGE, UNKNOWN_API_ERROR } from "../../../constants";
import default_image from "../../../images/other/default.png";
import "./CurrentQuestion.scss";

const CurrentQuestion = ({ status, updatingCurrentStatus }) => {
    const navigate = useNavigate();
    const jwt = getJWT();

    const postRequest = async (url) => {
        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + jwt
                },
                body: JSON.stringify({
                    exam: status.exam.id
                })
            });
    
            const data = await response.json();
        
            if (!response.ok || String(response.status).charAt(0) !== "2") {
                if (data?.error) {
                    throw data?.error;
                } else {
                    throw UNKNOWN_API_ERROR;
                }
            }
    
            if (data?.error) {
                throw data?.error;
            } else {
                navigate(EXAM_ROUTE, {
                    state: ""
                });
            }
        } catch (err) {
            if (err === UNAUTHORIZED_API_ERROR || err === NO_JWT_API_ERROR) {
                message.error(UNAUTHORIZED_MESSAGE);
                navigate(SIGN_OUT_ROUTE);
            } else if (err === INACTIVE_JWT_API_ERROR) {
                message.error(OTHER_DEVICE_MESSAGE);
                navigate(SIGN_OUT_ROUTE);
            } else {
                message.error(ERROR_MESSAGE);
            }
        } finally {
        }
    };

    const createExamStatus = async () => {
        if (!updatingCurrentStatus) {
            postRequest(CREATE_EXAM_STATUS_API);
        }
    };

    const continueExamStatus = async () => {
        if (!updatingCurrentStatus) {
            postRequest(CONTINUE_EXAM_STATUS_API);
        }
    };

    if (status.currentPage) {
        if (status.currentPage === "Kennis") {
            return (
                <section className="current-question status-knowledge">
                    <h3>Onderdeel Kennis</h3>
                    <p>Dit onderdeel bestaat uit 12 vragen over de onderwerpen:</p>
                    <ul>
                        <li>Gebruik van de weg</li>
                        <li>Voorrang en voor laten gaan</li>
                        <li>Bijzondere wegen, weggedeelten, weggebruikers en manoeuvres</li>
                        <li>Veilig rijden met het voertuig en reageren in noodsituaties</li>
                        <li>Verkeerstekens en aanwijzingen</li>
                        <li>Verantwoorde verkeersdeelname en milieubewust rijden</li>
                        <li>Wetgeving</li>
                        <li>Voertuigkennis</li>
                    </ul>
                    <p>Je mag bij dit onderdeel 2 fouten maken.</p>
                    <div
                        className="status-button"
                        onClick={(e) => {
                            e.preventDefault();
                            createExamStatus();
                        }}
                    >
                        <div className="text centered">Start Kennis</div>
                    </div>
                </section>
            );
        } else if (status.currentPage === "Inzicht") {
            return (
                <section className="current-question status-insight">
                    <h3>Onderdeel Inzicht</h3>
                    <p>Dit onderdeel bestaat uit 28 vragen over de onderwerpen:</p>
                    <ul>
                        <li>Gebruik van de weg</li>
                        <li>Voorrang en voor laten gaan</li>
                        <li>Bijzondere wegen, weggedeelten, weggebruikers en manoeuvres</li>
                        <li>Veilig rijden met het voertuig en reageren in noodsituaties</li>
                        <li>Verkeerstekens en aanwijzingen</li>
                        <li>Verantwoorde verkeersdeelname en milieubewust rijden</li>
                    </ul>
                    <p>Je mag bij dit onderdeel 3 fouten maken.</p>
                    <div
                        className="status-button"
                        onClick={(e) => {
                            e.preventDefault();
                            continueExamStatus();
                        }}
                    >
                        <div className="text centered">Start Inzicht</div>
                    </div>
                </section>
            );
        } else {
            return (
                <section className="current-question status-other">
                    <h3>{status.currentPage}</h3>
                    <p>Dit examen bestaat uit 50 vragen.</p>
                    <p>Je mag in dit examen 6 fouten maken.</p>
                    <div
                        className="status-button"
                        onClick={(e) => {
                            e.preventDefault();
                            createExamStatus();
                        }}
                    >
                        <div className="text centered">Start {status.currentPage}examen</div>
                    </div>
                </section>
            );
        }
    } else if (status.currentQuestion) {
        return (
            <section className="current-question status-question">
                <div className="question-number">
                    <div className="text centered">
                        {status.exam.voertuig === "Auto" ? ((status.numKnowledgeAnswered < status.numKnowledge ? status.numKnowledgeAnswered + 1 : status.numInsightAnswered + (status.numInsightAnswered === status.numInsight ? 0 : 1))
                        + " / " +
                        (status.numKnowledgeAnswered < status.numKnowledge ? status.numKnowledge : status.numInsight))
                        : ((status.numOtherAnswered + (status.numOtherAnswered === status.numOther ? 0 : 1))
                        + " / " +
                        status.numOther)}
                    </div>
                </div>

                <div className="picture">
                    <img src={status.currentQuestion.afbeelding ? (BASE + status.currentQuestion.afbeelding.url) : default_image} alt="" />
                </div>

                <div className="question">
                    <div className="text centered">{status.currentQuestion.vraag}</div>
                </div>

                <div className="options">
                    {status.currentQuestion.soort === "Opties"
                        ? <>
                            {status.currentQuestion.optieA ? <div className="status-option"><div className="text">{status.currentQuestion.optieA}</div></div> : <div />}
                            {status.currentQuestion.optieB ? <div className="status-option"><div className="text">{status.currentQuestion.optieB}</div></div> : <div />}
                            {status.currentQuestion.optieC ? <div className="status-option"><div className="text">{status.currentQuestion.optieC}</div></div> : <div />}
                        </>
                        :
                    status.currentQuestion.soort === "Ja/Nee"
                        ? <>
                            <div className="status-option"><div className="text">Ja</div></div>
                            <div className="status-option"><div className="text">Nee</div></div>
                            <div />
                        </>
                        :
                    status.currentQuestion.soort === "Invul"
                        ? <>
                            <div />
                            <div className="status-input">
                                <div className="text">vul hier je antwoord in</div>
                            </div>
                            <div />
                        </>
                        :
                    <></>}
                </div>

                <div
                    className="status-button"
                    onClick={(e) => {
                        e.preventDefault();
                        continueExamStatus();
                    }}
                >
                    <div className="text centered">Hervatten</div>
                </div>
            </section>
        );
    }

    return null;
};

export default CurrentQuestion;